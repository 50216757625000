/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.3.1): table.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import $ from 'jquery'
import Util from 'bootstrap/js/dist/util'
import CSV from 'jquery-csv'
import DataTable from 'datatables.net-bs4'
$('.table-data:not([data-load="table"]):not([data-load="filters"])').DataTable()

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME                  = 'table'
const VERSION               = '4.3.1'
const DATA_KEY              = 'ecas.table'
const EVENT_KEY             = `.${DATA_KEY}`
const DATA_API_KEY          = '.data-api'
const JQUERY_NO_CONFLICT    = $.fn[NAME]

const Default               = {
    caption     : false,
    file        : '',
    filter      : [],
    filters     : false,
    grouping    : false,
    headings    : false,
    hidden      : [],
    info        : false,
    limit       : false,
    load        : '',
    paging      : false,
    responsive  : '',
    searching   : false,
    sorting     : false,
    thead       : true
}

const DefaultType           = {
    caption     : 'string|boolean',
    file        : 'string',
    filter      : 'array',
    filters     : 'array|boolean|object',
    grouping    : 'array|boolean',
    headings    : 'boolean',
    hidden      : 'array',
    info        : 'boolean',
    limit       : 'number|boolean',
    load        : 'string',
    paging      : 'boolean',
    responsive  : 'string',
    searching   : 'boolean',
    sorting     : 'array|boolean',
    thead       : 'boolean'
}

const Selector              = {
    LOAD        : '[data-load="filters"],[data-load="table"]',
    LOADFILTERS : '[data-load="filters"]',
    LOADTABLE   : '[data-load="table"]',
    TBODY       : 'tbody',
    THEAD       : 'thead'
}

const Event                 = {
    LOAD_DATA_API   : `load${EVENT_KEY}${DATA_API_KEY}`,
}

const ClassName             = {
    DISPLAY_NONE    : 'd-none',
    TABLE           : 'table',
    TABLE_BORDER    : 'table-border',
    TABLE_DATA      : 'table-data',
    TABLE_STRIPED   : 'table-striped',
    THEAD_DARK      : 'thead-dark',
    THEAD_LIGHT     : 'thead-light'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Table {
    constructor(element, config) {
        this._config    = this._getConfig(config)
        this._table     = $(element)
        
        // console.log( this._config )

        if ( this._config.load == 'table' ) {
            this._spinner   = this.initSpinner()
            this._file      = this._getFile()
            
            this.buildTable()
        } else if ( this._config.load == 'filters' ) {
            this.buildFilters()
        }
    }

    // Getters
    static get VERSION() {
        return VERSION
    }

    // Public
    buildFilters() {
        var _this       = this
        var inputGroup  = ''
        var row         = $('<div class="row"/>')
        var container   = $('<div class="filters-table-data mb-3"/>').append( $('<div class="h5"/>').text('Filter By') ).append( row )

        if ( _this._table.parent().hasClass('table-responsive') ) {
            container.insertBefore( _this._table.parent() )
        } else {
            container.insertBefore( _this._table )
        }

        var dt          = _this._table.DataTable()
        var resetBtn    = $('<button class="btn btn-link" type="reset"/>').append( $('<span class="fas fa-redo-alt fa-flip-horizontal mr-2"/>') ).append( 'Reset' ).click( function() { _this._resetFilter( dt, container.find('.select-dropdown') ) })

        $.each( _this._config.filters, function(columnId, presets) {
            var column      = parseInt( columnId, 10 )
            var columnName  = _this._table.find('thead tr th:nth-child('+(column + 1)+')').text()
            var inputName   = columnName.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-').toLowerCase()
            var selectId    = 'select-'+inputName
            var inputGroup  = $('<div class="input-group" role="group" aria-label="'+columnName+' Options"/>')

            $.each( _this._getFilters(column), function(i, val) {
                let isChecked = ''
                
                if ( $.inArray(val, presets) !== -1 ) {
                    isChecked = 'checked'
                }

                var input = $('<input class="custom-control-input" id="'+inputName+'-'+i+'" name="'+inputName+'" type="checkbox" value="'+val+'" '+isChecked+'/>');
                
                input.change( function() {
                    _this._setFilterLabel( container.find('.select-dropdown') )
                    _this._filterTable( dt, container.find('.select-dropdown') )
                })

                inputGroup.append( $('<div class="custom-control custom-checkbox"/>').append( input ).append( 
                        $('<label class="custom-control-label" for="'+inputName+'-'+i+'" name="'+inputName+'">'+val+'</label>')
                    ) 
                ) 
            })

            row.append( 
                $('<div class="col-sm-6 col-md-6"/>').append( 
                    $('<div class="input-group flex-column filter-group mb-3" />').append( 
                        $('<label class="small font-weight-bold" for="select-sender">'+columnName+'</label>') 
                    ).append(
                        $('<button id="'+selectId+'" type="button" class="select-dropdown dropdown-toggle form-control form-control-lg w-100" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true"/>').text('Select…')
                    ).append(
                        $('<div class="select-dropdown-menu dropdown-menu" aria-labelledby="'+selectId+'" data-filter-column-target="'+column+'"/>').append( inputGroup )
                    )
                ) 
            )
        })

        row.append( $('<div class="col-12 text-right"/>').append( resetBtn ) )

        _this._setFilterLabel( container.find('.select-dropdown') )
        _this._filterTable( dt, container.find('.select-dropdown') )
    }

    buildTable() {
        var _this = this

        return $.ajax({
            async       : false,
            dataType    : 'text',
            type        : 'GET',
            url         : this._file.url,
            error       : function (e) { throw new Error("API call Failed: ", e) },
            success: function (data) {
                var rows    = CSV.toArrays(data)

                $.each(_this._config.filter, function(filterIdx,filter) {
                    var fIdx = filter[0] - 1
                    var fVal = filter[1]
                    
                    rows = $.grep(rows, function(row, rowIdx) {
                        if ( _this._file.hasHeadings && rowIdx < 1 ) {
                            return true
                        } else {
                            return row[fIdx] == fVal
                        }
                    })
                })

                if ( _this._config.limit ) {
                    if( _this._file.hasHeadings ) {
                        var headingRow = rows.shift()
                    }

                    if ( _this._config.sorting ) {
                        var sortCol     = _this._config.sorting[0][0] - 1
                        var sortType    = _this._config.sorting[0][1]

                        rows.sort( function(a, b) {
                            if ( sortType == 'asc' ) {
                                return a[sortCol] > b[sortCol]
                            } else if ( sortType == 'desc' ) {
                                return a[sortCol] < b[sortCol]
                            }
                        });
                    }
                    
                    rows = rows.slice(0, _this._config.limit)

                    if( _this._file.hasHeadings ) {
                        rows.unshift( headingRow )
                    }
                }

                if ( _this._config.caption ) {
                    _this._table.append($('<caption/>').text(_this._config.caption))
                }

                _this._spinner.remove()

                if ( _this._file.hasHeadings ) {
                    var columns = rows.shift()                    
                    var theadTr = $('<tr/>')
        
                    $.each(columns, function(idx, val) {
                        theadTr.append($('<th/>').text(val))
                    })

                    var thead = $('<thead/>').addClass(ClassName.THEAD_DARK).append(theadTr)

                    _this._table.append(thead)

                    _this._table.append($('<tbody/>').addClass(ClassName.THEAD_LIGHT))

                    if ( _this._config.grouping && _this._table.hasClass(ClassName.TABLE_STRIPED) ) {
                        _this._table.removeClass(ClassName.TABLE_STRIPED)
                    }

                    _this._spinner.remove()
                    
                    _this._table.DataTable({
                        columns         : _this._dtColumns(columns),
                        columnDefs      : _this._dtColumnDefs(),
                        data            : rows,

                        initComplete    : function( settings, json ) {
                            if ( !_this._config.thead ) {
                                thead.addClass(ClassName.DISPLAY_NONE)
                            }
                        },
                   
                        order           : _this._dtOrder(),
                        orderFixed      : _this._dtOrderFixed(),

                        info            : _this._config.info,
                        paging          : _this._config.paging,
                        searching       : _this._config.searching,
                        responsive      : true,
                        drawCallback    : function( settings ) {
                            var api     = this.api()
                            var rows    = api.rows({page:'current'}).nodes()
                            var last    = null

                            if ( _this._config.grouping ) {
                                api.column(_this._config.grouping[0]-1, {page:'current'}).data().each( function(group, i) {
                                    if ( last !== group ) {
                                        var groupHeading    = api.column(_this._config.grouping[0]-1, {page:'current'}).header().textContent
                                        var groupEl         = '<tr class="group h5"><th colspan="' + ( columns.length - 1 ) + '">' + groupHeading + ': ' + group + '</th></tr>'

                                        $(rows).eq( i ).before(groupEl)
                                        
                                        last = group
                                    }
                                })
                            }
                        }
                    })
                } else {
                    var tbody   = _this._table.append($('<tbody/>').addClass(ClassName.THEAD_LIGHT))

                    $.each(rows, function(rowIdx, columns) {
                        var tr  = $('<tr/>').appendTo(tbody)

                        $.each(columns, function(idx, val) {
                            tr.append($('<td/>').text(val))
                        })
                    })
                }

                switch ( _this._config.responsive ) {
                    case 'never': 
                        break
                    case 'always': 
                        _this._table.wrap('<div class="table-responsive"></div>')
                        break
                    default: 
                        _this._table.wrap('<div class="table-responsive-'+_this._config.responsive+'"></div>')
                }
            }
        })      
    }

    dispose() {
        this._table.off(EVENT_KEY)
        $.removeData(this._element, DATA_KEY)

        this._config            = null
        this._table             = null
        this._spinner           = null
        this._file              = null
    }

    initSpinner() {
        var icon    = $('<div class="spinner-border spinner-border-sm h5" role="status" aria-hidden="true"/>')
        var text    = $('<div class="mx-3 h5"/>').text('Loading Table')
        var spinner = $('<caption class="d-flex align-items-center"/>').append(icon).append(text)

        this._table.prepend(spinner)
        
        return spinner
    }

    // Private
    _dtColumns(columns) {
        var _this       = this
        var dtColumns   = []

        $.each(columns, function(idx, val) {
            dtColumns.push({defaultContent:''})
        })

        return dtColumns
    }

    _dtColumnDefs() {
        var _this           = this
        var dtColumnDefs    = []

        if ( _this._config.grouping ) {
            var groupingColumnNumber    = _this._config.grouping[0]

            dtColumnDefs.push({visible:false, targets:groupingColumnNumber-1})
        }

        $.each( _this._config.hidden, function(i, col) {
            dtColumnDefs.push({visible:false, targets:col-1})
        })

        return dtColumnDefs
    }

    _dtOrder(theadColumns) {
        var _this       = this
        var dtOrder     = []

        if ( _this._config.sorting ) {
            $.each(_this._config.sorting, function(idx,val) {
                var sortingColumnNumber = val[0]
                var sortingColumnOrder  = val[1]

                dtOrder.push([sortingColumnNumber-1,sortingColumnOrder])
            })
        }

        return dtOrder
    }

    _dtOrderFixed() {
        var _this           = this
        var dtOrderFixed    = []

        if ( _this._config.grouping ) {
            var groupingColumnNumber    = _this._config.grouping[0]
            var groupingColumnOrder     = _this._config.grouping[1]

            dtOrderFixed  = [groupingColumnNumber-1,groupingColumnOrder]
        }

        return dtOrderFixed
    }

    _getConfig(config) {
        config = {
            ...Default,
            ...config
        }
        
        Util.typeCheckConfig(NAME, config, DefaultType)
        
        return config
    }

    _getFile() {
        var _this       = this
        var extension   = _this._config.file.substr( (_this._config.file.lastIndexOf('.') +1) ).toLowerCase()

        var file        = {
            ext         : extension,
            url         : _this._config.file,
            hasHeadings : _this._config.headings
        }

        switch(extension) {
            case 'csv':
                break;
            default: 
                throw new Error("Improper file type supplied.")
        }

        return file
    }

    _getFilters( column ) {
        var _this   = this
        var filters = []
        
        _this._table.find('tbody tr td:nth-child('+(column + 1)+')').each( function() {
            var str    = $(this).text()
            var strs   = str.split(",")
            
            $.each(strs, function( i, val ) {
                var value = $.trim(val)

                if ( $.inArray(value, filters) === -1 ) {
                    filters.push( value )
                }
            })
        })

        filters.sort()
        
        return filters
    }

    _filterTable( dt, menus ) {
        var _this = this

        menus.each( function() {
            let columnTarget    = $(this).next('.select-dropdown-menu').attr("data-filter-column-target");
            let keywords        = $(this).next('.select-dropdown-menu').find("input[type=checkbox]:checked").map( function() { return $(this).val() }).get().join("|")

            dt.column( columnTarget ).search(keywords, true, false, true)
        })

        dt.draw()
    }

    _resetFilter( dt, menus ) {
        var _this = this

        menus.each( function() {
            let columnTarget    = $(this).next('.select-dropdown-menu').attr("data-filter-column-target");

            $(this).next('.select-dropdown-menu').find("input[type=checkbox]").each( function() {
                if( $.inArray( $(this).val(), _this._config.filters[columnTarget] ) !== -1 ) {
                    $(this).prop("checked", true)    
                } else {
                    $(this).prop("checked", false)
                }
            })
        })
        
        _this._setFilterLabel( menus )
        _this._filterTable( dt, menus )
    }

    _setFilterLabel( menus ) {
        var _this = this

        menus.each( function() {
            let button  = $(this)
            let checked = button.next('.select-dropdown-menu').find("input[type=checkbox]:checked")

            if( checked.length === 0 ) {
                $(this).html("Select&hellip;");
            } else if ( checked.length <= 2 ) {
                $(this).html("<span class='sr-only'>" + checked.length + " selected: </span>" + checked.map( function() { return $(this).val() }).get().join(", ") )
            } else {
                $(this).html(checked.length+' selected')
            }
        })
    }

    // Static
    static _jQueryInterface(config) {
        return this.each( function () {
            let data    = $(this).data(DATA_KEY)
            let _config = {
                ...Default,
                ...$(this).data()
            }

            if (typeof config === 'object') {
                _config = {
                    ..._config,
                    ...config
                }
            }

            if (!data) {
                data = new Table(this, _config)
                $(this).data(DATA_KEY, data)
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`)
                }
                data[config]()
            }
        })
    }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

$(window).on( Event.LOAD_DATA_API, () => {
    const tables = [].slice.call( document.querySelectorAll(Selector.LOAD) )
    
    for ( let i = 0, len = tables.length; i < len; i++ ) {
        const $table = $(tables[i])
        Table._jQueryInterface.call( $table, $table.data() )
    }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME]              = Table._jQueryInterface
$.fn[NAME].Constructor  = Table
$.fn[NAME].noConflict   = () => {
$.fn[NAME]              = JQUERY_NO_CONFLICT
    return Table._jQueryInterface
}

export default Table